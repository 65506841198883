<script>
  import { get, call } from 'vuex-pathify'
  import { DIRECTION_FORWARD } from '@/router'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import screen from '@/helpers/screen'
  import CustomizeVariantCard from '@/components/customize/CustomizeVariantCard'
  import LogoChoicePlus from '@/images/logos/LogoChoicePlus.svg'
  import { ChoiceAutoSelectModalTypes } from '@/store/customize'

  const guardMixin = createGuardMixin(async ({ store, route, redirect }) => {
    // Make sure the current choice parameter is valid (between 1 and the total choice count)
    // See explanation for `mockRoute` in the parent `Customize` view
    const ensureFreshConfig = store.dispatch('config/ensureFresh')
    const ensureFreshCustomizeData = store.dispatch('customize/ensureFresh')
    await Promise.all([ensureFreshConfig, ensureFreshCustomizeData])
    store.set('customize/mockRoute', route)
    const currentChoice = store.get('customize/currentChoice')
    const choiceCount = store.get('customize/choiceCount')
    store.set('customize/mockRoute', null)
    if (currentChoice < 1 || currentChoice > choiceCount) {
      return redirect({ name: 'CustomizeStart' })
    }
  })

  export default {
    components: {
      CustomizeVariantCard,
      LogoChoicePlus,
    },
    mixins: [guardMixin],
    data() {
      return {
        ChoiceAutoSelectModalTypes,
        chosenVariantId: null,
        submitting: false,
        showChoiceAlert: false,
        showChoiceAlertModal: false,
        isBoxChoiceSaved: false,
      }
    },
    computed: {
      ...get('feature', ['flags']),
      ...get('general', ['navigating']),
      ...get('customize', [
        'isSelectedSubscriptionQuarterly',
        'selectedBoxCart',
        'currentChoice',
        'allPossibleChoiceCounts',
        'currentChoiceVariants',
        'currentChoiceVariantIds',
        'isLastChoice',
        'hasMoreChoicesComing',
        'choiceChooseForMeVariant',
        'savedChooseForMeVariantIds',
        'mysteryChoiceSkuBase',
        'choiceChooseForMeVariantBaseId',
        'isMysteryVariant',
      ]),
      ...get('choicePlusCart', ['cartQuantity', 'lineItems']),
      screen,
      isSubscribeCustomizeFlow() {
        return Boolean(this.$route?.params?.subscribe)
      },
      chosenVariant() {
        if (this.isChooseForMeVariantSelected) {
          return this.choiceChooseForMeVariant()
        } else {
          return this.currentChoiceVariants?.find((variant) => variant.id === this.chosenVariantId)
        }
      },
      submitEnabled() {
        const isBoxChoiceSelected = Boolean(this.chosenVariant)
        const isChoiceSelected = isBoxChoiceSelected || this.isChooseForMeVariantSelected
        return isChoiceSelected && !this.submitting
      },
      submitLabel() {
        return this.chosenVariant ? 'Save & Continue' : 'Choose a product'
      },
      hideBackButton() {
        return this.isSubscribeCustomizeFlow && this.currentChoice === 1
      },
      previousRoute() {
        if (this.currentChoice <= 1) {
          if (this.skipFirstScreen) {
            return { name: 'Home' }
          } else {
            return { name: 'CustomizeStart' }
          }
        } else {
          return {
            name: 'CustomizeChoice',
            params: { ...this.$route.params, choice: this.currentChoice - 1 },
          }
        }
      },
      nextRoute() {
        if (this.isLastChoice) {
          return { name: 'CustomizeComplete', params: { ...this.$route.params } }
        }
        return {
          name: 'CustomizeChoice',
          params: { ...this.$route.params, choice: this.currentChoice + 1 },
        }
      },
      headerCopy() {
        if (this.chosenVariantId) {
          return 'Buy more products or click ‘Next’ to continue customizing your box.'
        } else {
          return 'Select a product to customize your box.'
        }
      },
      currentChoiceHasChoicePlusOptions() {
        return this.currentChoiceVariants.some(
          (variant) => variant.stock > 0 || variant.hasUnlimitedStock
        )
      },
      isChooseForMeEnabled() {
        return this.flags['choose-for-me'] ?? false
      },
      isChooseForMeVariantSelected() {
        return this.chosenVariantId === this.choiceChooseForMeVariant().id
      },
      isMysteryChoiceSelected() {
        return this.isMysteryVariant(this.chosenVariant)
      },
      otherChoiceChosen() {
        const allowSelectingBoxChoice = false
        return this.isChooseForMeVariantSelected
          ? allowSelectingBoxChoice
          : Boolean(this.chosenVariantId)
      },
      isMysteryInCurrentChoiceVariants() {
        for (const variant of this.currentChoiceVariants) {
          if (variant?.sku?.toLowerCase()?.includes(this.mysteryChoiceSkuBase.toLowerCase())) {
            return true
          }
        }
        return false
      },
      gridStyle() {
        return {
          gridTemplateColumns: `repeat(auto-fit, minmax(min(${
            this.screen.md ? 170 : 150
          }px, 100%), max-content))`,
        }
      },
    },
    watch: {
      cartQuantity() {
        if (this.cartQuantity) {
          this.reviewOrder()
        }
      },
    },
    created() {
      window.scrollTo(0, 0)
      const isChooseForMeVariantSaved = this.savedChooseForMeVariantIds.includes(
        this.choiceChooseForMeVariant().id
      )
      this.chosenVariantId =
        this.currentChoiceVariants?.find((variant) => variant.chosen)?.id ?? null
      if (this.chosenVariantId) {
        this.isBoxChoiceSaved = true
      }
      if (!this.chosenVariantId && isChooseForMeVariantSaved) {
        this.chosenVariantId = this.choiceChooseForMeVariant().id
      }
    },
    methods: {
      ...call('customize', [
        'updateChoice',
        'saveChooseForMeVariantId',
        'setChoiceAutoSelectModalVisible',
      ]),
      ...call('choicePlusCart', ['removeCartItem', 'reviewOrder']),
      chooseVariant(event) {
        this.chosenVariantId = event

        if (!event || this.isChooseForMeVariant(event)) {
          this.clearLineItems()
          this.showChoiceAlert = false
        } else if (this.currentChoiceHasChoicePlusOptions) {
          this.showChoiceAlert = true
        }
      },
      isChooseForMeVariant(variantId) {
        return String(variantId).includes(this.choiceChooseForMeVariantBaseId)
      },
      handleNavigateToDetails(variant) {
        if (this.isMysteryVariant(variant)) {
          this.setChoiceAutoSelectModalVisible({
            modalVisible: true,
            choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.MYSTERY,
          })
        } else {
          this.navigateToDetails(variant.id)
        }
      },
      clearLineItems() {
        this.lineItems.forEach(({ variantId }) => {
          if (this.currentChoiceVariantIds.includes(variantId)) {
            this.removeCartItem({ variantId })
          }
        })
      },
      async submit() {
        this.submitting = true
        try {
          if (this.isChooseForMeVariantSelected) {
            this.saveChooseForMeVariantId(this.choiceChooseForMeVariant().id)
          } else {
            await this.updateChoice({
              boxCartId: this.selectedBoxCart.id,
              productId: this.chosenVariant.productId,
              variantId: this.chosenVariant.id,
            })
          }
          this.$navigate(this.nextRoute, DIRECTION_FORWARD)
        } catch (error) {
          this.submitting = false
          throw error
        }
      },
      closeChoiceAlert() {
        this.showChoiceAlert = false
        this.showChoiceAlertModal = false
      },
      openChoiceAlertModal() {
        this.showChoiceAlertModal = true
      },
      shippingNotice(variant) {
        const notice = {
          // Remove after 12/15/2021
          'CB003066-HLV-FA21':
            'Due to Covid-19 related supply chain delays and port congestion, new selections of this item will cause your box to ship no earlier than early-mid December.',
          'CB002972-JIN-FA21':
            'Due to Covid-19 related supply chain delays and port congestion, new selections of this item will cause your box to ship no earlier than late November.',
          // Remove after 3/15/2021
          'CB003024-JIN-SU21':
            'Due to Covid-19 related supply chain delays and port congestion, selections of this item will likely cause your box to ship in mid-January to early February. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB003090-SUS-WI22':
            'Due to Covid-19 related supply chain delays and port congestion, selections of this item will likely cause your box to ship in mid-January to early February. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB004306-NFC-WI22':
            'Due to Covid-19 related supply chain delays and port congestion, selections of this item will likely cause your box to ship in mid-January to late February. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB004322-AYX-WI22':
            'Due to Covid-19 related supply chain delays and port congestion, selections of this item will likely cause your box to ship in early February to early March. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB003110-HLV-WI22':
            'Based on current estimates: due to Covid-19 related supply chain delays and port congestion, new selections of this item will cause your box to ship in mid January to mid February. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB003111-PUD-WI22':
            'Due to Covid-19 related supply chain delays and port congestion, selections of this item will likely cause your box to ship in mid January. Choice+ purchases will ship separately but are subject to the same estimated timeframes.',
          'CB004301-VDA-WI22': `NOT AVAILABLE FOR SHIPMENT TO CANADA. If you're in Canada and select this product our team will substitute in a different product from this choice tier!`,
          'CB004323-CFB_SP22':
            'Spring Boxes customized to include this item will begin shipping in late March in line with the general Spring shipping window, but may continue shipping into late May. Choice+ purchases of this item are subject to the same estimated timeframe.',
          'CB004324-CFB_SP22':
            'Spring Boxes customized to include this item will begin shipping in late March in line with the general Spring shipping window, but may continue shipping into late May. Choice+ purchases of this item are subject to the same estimated timeframe.',
          'CB004325-CFB_SP22':
            'Spring Boxes customized to include this item will begin shipping in late March in line with the general Spring shipping window, but may continue shipping into late May. Choice+ purchases of this item are subject to the same estimated timeframe.',
        }
        return notice[variant.sku] ?? null
      },
      navigateToDetails(variantId) {
        this.$navigate({
          name: 'CustomizeChoiceDetails',
          params: {
            ...this.$route.params,
            variantId: variantId,
          },
        })
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="$route.name === 'CustomizeChoiceDetails' && !screen.md" key="details">
      <RouterView />
    </div>

    <section v-else key="main" class="pb-15 sm:pb-0">
      <BaseContainer max-width="3xl">
        <h2 v-if="currentChoiceVariants.length > 0" :class="`mt-2 mb-8 text-center text-xs`">
          {{ headerCopy }}
        </h2>

        <BaseAlert
          v-if="showChoiceAlert && !isChooseForMeVariantSelected && !isMysteryChoiceSelected"
          class="hidden sm:flex justify-between max-w-100 | mb-4 w-3/4 items-center mx-auto"
          :show-modal="showChoiceAlertModal"
          @dismiss="showChoiceAlertModal = false"
        >
          <BaseIcon class="w-1/5">
            <LogoChoicePlus class="w-full pb-1" />
          </BaseIcon>

          <div class="text-xs flex">
            <p role="alert" class="mr-2"
              >Buy more for {{ $formatPrice(currentChoiceVariants[0].price) }} each.</p
            >
            <button class="underline" @click="openChoiceAlertModal">Learn More</button>
          </div>

          <div>
            <button
              class="bg-white border border-black rounded-full p-1 shadow right-0 top-0 -mt-1"
              type="button"
              @click="closeChoiceAlert"
            >
              <BaseIcon :size="3">
                <IconXThick />
              </BaseIcon>
            </button>
          </div>

          <template v-slot:modal>
            <div>
              <BaseIcon class="w-1/2 mx-auto mb-6">
                <LogoChoicePlus class="w-full pb-1" />
              </BaseIcon>

              <p class="text-xs text-center mb-8">
                Want more than one product? With Choice+ you can purchase additional choice products
                from each category at a discounted price.
              </p>

              <BaseButton @click="closeChoiceAlert">Got it</BaseButton>
            </div>
          </template>
        </BaseAlert>

        <ul class="grid justify-center gap-x-3 gap-y-8 mb-8" :style="gridStyle">
          <li v-for="variant in currentChoiceVariants" :key="variant.id" class="w-45 max-w-full">
            <CustomizeVariantCard
              :variant="variant"
              :chosen="chosenVariantId === variant.id"
              :other-choice-chosen="otherChoiceChosen"
              :display-only="variant.selectionStatus === 'displayOnly'"
              :shipping-notice="shippingNotice(variant)"
              has-cta
              @choose="chooseVariant($event)"
              @details="handleNavigateToDetails(variant)"
            />
          </li>
          <li
            v-if="isChooseForMeEnabled && !isBoxChoiceSaved && !isMysteryInCurrentChoiceVariants"
            :key="choiceChooseForMeVariant().id"
            class="w-45 max-w-full"
          >
            <CustomizeVariantCard
              :variant="choiceChooseForMeVariant()"
              :chosen="chosenVariantId === choiceChooseForMeVariant().id"
              :other-choice-chosen="otherChoiceChosen"
              :display-only="choiceChooseForMeVariant().selectionStatus === 'displayOnly'"
              has-cta
              @choose="chooseVariant($event)"
              @details="
                setChoiceAutoSelectModalVisible({
                  modalVisible: true,
                  choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.CHOOSE_FOR_ME,
                })
              "
            />
          </li>
        </ul>
        <BaseDivider v-if="screen.md" color="dawn-dk2" class="mt-6 mb-4 w-170 mx-auto" />
        <div class="hidden sm:flex justify-center">
          <BaseButton
            v-if="!hideBackButton"
            color="primary"
            :disabled="submitting"
            outline-only
            class="w-40 mr-3"
            :to="previousRoute"
          >
            Back
          </BaseButton>
          <BaseButton
            color="primary-alt"
            :disabled="submitting || !submitEnabled"
            data-cy="submit"
            class="w-40 bg-dawn"
            @click="submit"
          >
            Next
          </BaseButton>
        </div>

        <BaseTransitionFade>
          <div v-if="submitting" class="z-10 absolute inset-0 -m-4">
            <BaseSpinner overlay="fixed" overlay-color="dawn-lt3" />
          </div>
        </BaseTransitionFade>
      </BaseContainer>

      <Portal to="modal">
        <RouterView />
      </Portal>

      <Portal to="fixedBottom">
        <BaseTransitionFixedBottom>
          <div v-if="!navigating" class="sm:hidden flex flex-col">
            <BaseAlert
              v-if="showChoiceAlert && !isChooseForMeVariantSelected && !isMysteryChoiceSelected"
              class="grid grid-cols-3 gap-4 | mb-4 w-3/4 items-center mx-auto"
              :show-modal="showChoiceAlertModal"
              @close="closeChoiceAlert"
              @dismiss="showChoiceAlertModal = false"
            >
              <BaseIcon class="col-span-1">
                <LogoChoicePlus class="w-full pb-1" />
              </BaseIcon>

              <div class="col-span-2 text-xs">
                <p role="alert"
                  >Buy more for {{ $formatPrice(currentChoiceVariants[0].price) }} each.</p
                >
                <button class="underline" @click="openChoiceAlertModal">Learn More</button>
              </div>

              <template v-slot:modal>
                <div>
                  <BaseIcon class="w-1/2 mx-auto mb-6">
                    <LogoChoicePlus class="w-full pb-1" />
                  </BaseIcon>

                  <p class="text-xs text-center mb-8">
                    Want more than one product? With Choice+ you can purchase additional choice
                    products from each category at a discounted price.
                  </p>

                  <BaseButton @click="closeChoiceAlert">Got it</BaseButton>
                </div>
              </template>
            </BaseAlert>

            <div class="flex shadow-md">
              <BaseButton
                v-if="!hideBackButton"
                :disabled="submitting"
                color="primary"
                outline-only
                class="w-1/2 bg-dawn"
                :to="previousRoute"
              >
                Back
              </BaseButton>
              <BaseButton
                color="primary-alt"
                :disabled="!submitEnabled"
                data-cy="submit"
                :class="`${hideBackButton ? 'w-full' : 'w-1/2'}`"
                @click="submit"
              >
                Next
              </BaseButton>
            </div>
          </div>
        </BaseTransitionFixedBottom>
      </Portal>
    </section>
  </div>
</template>
